import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
    RefundOrdersResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

export const ordersApi = createApi({
    reducerPath: "ordersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
        credentials: "include",
        headers: {
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
    }),
    tagTypes: ["RefundOrdersTemplate"],
    endpoints: (build) => ({
        getRefundOrdersTemplate: build.query<
            RefundOrdersResponse,
            void
        >({
            query: () => ({
                url: "/refund-orders-templates"
            }),
        }),
        bulkOrderRefunds: build.mutation<
            void,
            {
                orders: string[]
                percentage: string
                template: string
            }
        >({
            query: ({ orders, percentage, template }) => ({
                method: 'POST',
                url: "/refund-orders",
                body: {
                    data: {
                        attributes: {
                            orders,
                            percentage,
                            template,
                        },
                    },
                }
            }),
            transformErrorResponse: (response) =>
                processErrorResponse("Error when refunding orders!", response),
        })
    }),
})

export const {
    useGetRefundOrdersTemplateQuery,
    useBulkOrderRefundsMutation,
} = ordersApi