

import { Suspense } from "react"
import { Await, useLoaderData } from "react-router-dom"

import { Loading } from "../../components/Loading"
import { DataLoaderClientReferrals } from "./ClientReferralTypes"

const ClientReferrals = () => {
    const dataLoader = useLoaderData() as DataLoaderClientReferrals
    return (
        <Suspense fallback={<Loading />}>
            <Await resolve={dataLoader.data} errorElement={<div />}>
                {(data) => {
                    const [{ ClientReferrals }] = data
                    return <ClientReferrals />
                }}
            </Await>
        </Suspense>
    )
}

export default ClientReferrals
