import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { ActivityLogResponse, AutocompleteActivityLogsResponse } from "../screens/ActivityLogs/activityLogsTypes"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"

export const systemEventsApi = createApi({
    reducerPath: "systemEvents",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
        credentials: "omit",
        headers: {
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
    }),
    tagTypes: ["SystemEvents", "SystemEventsAutocomplete"],
    endpoints: (build) => ({
        getActivityLogs: build.query<
            ActivityLogResponse,
            { customer: string; type: string; start: string; end: string; limit: number; page: number }
        >({
            query: ({ customer, type, start, end, limit, page }) => ({
                url:
                    "/activity-log?" +
                    new URLSearchParams({
                        customer,
                        type,
                        start,
                        end,
                        limit: String(limit),
                        page: String(page),
                    }),
            }),
            providesTags: () => [{ type: "SystemEvents" }],
        }),
        getAutocompleteCustomerOrType: build.query<
            AutocompleteActivityLogsResponse,
            { text: string; type: 'customer' | 'type' }
        >({
            query: ({ type, text }) => ({
                url:
                    "/activity-log/autocomplete?" +
                    new URLSearchParams({
                        search: text,
                        type,
                    }),
            }),
            providesTags: () => [{ type: "SystemEventsAutocomplete" }],
        }),
    }),
})

export const {
    useLazyGetAutocompleteCustomerOrTypeQuery,
    useGetAutocompleteCustomerOrTypeQuery,
    useGetActivityLogsQuery,
} = systemEventsApi