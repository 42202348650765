import { Suspense } from "react"
import { Await, useLoaderData } from "react-router-dom"

import { Loading } from "../../components/Loading"
import { DataLoaderPage } from "../../types/types"
import { ActivityLogsPromiseData } from "./activityLogsTypes"

export const ActivityLogs = () => {
    const dataLoader = useLoaderData() as DataLoaderPage
    return (
        <Suspense fallback={<Loading />}>
            <Await resolve={dataLoader.data} errorElement={<div />}>
                {(data: ActivityLogsPromiseData) => {
                    const [{ ActivityLogsPage }] = data
                    return <ActivityLogsPage />
                }}
            </Await>
        </Suspense>
    )
}
