import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
    PromoterDiscountLinkResponse, PromoterDiscountLinksResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

export const promoterDiscountLinksApi = createApi({
    reducerPath: "promoterDiscountLinksApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
        credentials: "include",
        headers: {
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
    }),
    tagTypes: ["PromoterDiscountLinks"],
    endpoints: (build) => ({
        getPromoterDiscountLinks: build.query<
            PromoterDiscountLinksResponse,
            { search_query: string; page: number; limit: number }
        >({
            query: ({ search_query, page, limit }) => ({
                url:
                    "/promoter-discount-links?" +
                    new URLSearchParams({
                        search_query,
                        page: String(page),
                        limit: String(limit),
                    }),
            }),
            providesTags: () => [{ type: "PromoterDiscountLinks" }],
        }),
        createPromoterDiscountLink: build.mutation<
            PromoterDiscountLinkResponse,
            {
                limit: number
                page: number
                search_query: string
                shopProductId: string
                shopCustomerId: string
                autoApplyPromoCode: string
                enabled: boolean
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when creating promoter discount link!", response),
            query: (patch) => ({
                url: `/promoter-discount-link`,
                method: "POST",
                body: {
                    data: {
                        attributes: patch,
                    },
                },
            }),
        }),
        editPromoterDiscountLink: build.mutation<
            PromoterDiscountLinkResponse,
            {
                id: string
                limit: number
                page: number
                search_query: string
                shopProductId: string
                shopCustomerId: string
                autoApplyPromoCode: string
                enabled: boolean
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when editing promoter discount link!", response),
            query: ({ id, ...patch }) => ({
                url: `/promoter-discount-link/${id}`,
                method: "PATCH",
                body: {
                    data: {
                        attributes: patch,
                    },
                },
            }),
            async onQueryStarted({ id, limit, page, search_query }, { dispatch, queryFulfilled }) {
                try {
                    const data = await queryFulfilled
                    dispatch(
                        promoterDiscountLinksApi.util.updateQueryData(
                            "getPromoterDiscountLinks",
                            { limit, page, search_query },
                            (draft) => {
                                const index = draft.data.promoter_links.findIndex(
                                    (promoterDiscountLink) => promoterDiscountLink.id === id
                                )
                                if (index !== -1) {
                                    draft.data.promoter_links[index] = data.data.data.promoter_discount_link
                                }
                            }
                        )
                    )
                } catch { }
            },
        }),
        deletePromoterDiscountLink: build.mutation<
            void,
            {
                id: string
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when deleting promoter discount link!", response),
            query: ({ id }) => ({
                url: `/promoter-discount-link/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['PromoterDiscountLinks']
        }),
    }),
})

export const {
    useGetPromoterDiscountLinksQuery,
    useCreatePromoterDiscountLinkMutation,
    useEditPromoterDiscountLinkMutation,
    useDeletePromoterDiscountLinkMutation,
} = promoterDiscountLinksApi